import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { map, pipe, skipWhile, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { ToastService } from '@ideals/components/toast';
import { AuthService } from '@ideals/core/auth';
import { RootStoreFacade } from '@ideals/core/root-store';
import { withStoreEvents } from '@ideals/utils/with-store-events';

import { DetailedProject, HttpError, PremierPlanFeatures, ProjectGroup, ProjectUser, RequestError } from '../../models';
import { ProjectUsersService } from '../../services/project-users';
import { ProjectsService } from '../../services/projects';
import { checkProjectStatus } from '../../utils/check-project-status';
import { ErrorsStore } from '../errors';
import { ProjectsStore } from '../projects';

export interface ProjectState {
  readonly currentUser: ProjectUser | undefined;
  readonly loading: boolean;
  readonly projectIncludeInactive: DetailedProject | undefined;
  readonly updating: boolean;
  readonly viewAsGroup: ProjectGroup | undefined;
}

export const initialState: ProjectState = {
  currentUser: undefined,
  loading: true,
  projectIncludeInactive: undefined,
  updating: false,
  viewAsGroup: undefined,
};

export const ProjectStore = signalStore(
  { protectedState: false },
  withState(initialState),
  withStoreEvents<{
    sendUpgradeSubscriptionPlanRequestFailure: void;
    sendUpgradeSubscriptionPlanRequestSuccess: void;
    updateLinkFailure: void;
    updateLinkSuccess: void;
    updateProjectFailure: RequestError;
    updateProjectSuccess: Partial<DetailedProject>;
  }>(),
  withComputed(({ projectIncludeInactive }, rootStoreFacade = inject(RootStoreFacade)) => ({
    hostId: rootStoreFacade.selectRouterParam('hostId'),
    project: computed(() => {
      if (projectIncludeInactive() && checkProjectStatus(projectIncludeInactive()!, ['Active', 'Preparation'])) {
        return projectIncludeInactive();
      }

      return undefined;
    }),
    projectName: rootStoreFacade.selectRouterParam('projectName'),
  })),
  withMethods((store) => {
    const authService = inject(AuthService);
    const errorsStore = inject(ErrorsStore);
    const projectsService = inject(ProjectsService);
    const projectsStore = inject(ProjectsStore);
    const projectUsersService = inject(ProjectUsersService);
    const router = inject(Router);
    const toastService = inject(ToastService);
    const translateService = inject(TranslateService);

    const setUpdatedProjectToProjects = (name: string, updatedProject: Partial<DetailedProject>): void => {
      projectsStore.setUpdatedProject(name, updatedProject);
    };

    const loadProject = rxMethod<{ hostId: string; name: string; silent?: boolean; }>(
      pipe(
        tap(({ silent }) => patchState(store, {
          loading: !silent,
          projectIncludeInactive: silent ? store.projectIncludeInactive() : undefined,
        })),
        switchMap(({ hostId, name }) => projectsService.loadProject(hostId, name).pipe(
          switchMap((project) => {
            if (!authService.user.roomAccess.includes(project.id)) {
              authService.refreshToken();
            }

            return projectUsersService.loadCurrentUser(project).pipe(map((currentUser) => ({ project, currentUser })));
          }),
          tapResponse(
            ({ project, currentUser }) => {
              patchState(store, { currentUser, loading: false, projectIncludeInactive: project });
              setUpdatedProjectToProjects(project.name, project);
            },
            (error: HttpError) => {
              patchState(store, { loading: false });
              errorsStore.requestError(error);
            },
          ),
        )),
      ),
    );

    const navigateToUpdatedProject = (project: DetailedProject): void => {
      router.navigate(['/project', project.hostId, project.name, 'settings', 'project']);
    };

    const updateFailure = (error: RequestError): void => {
      store.emit('updateProjectFailure', error);
      patchState(store, { loading: false, updating: false });
      errorsStore.requestError(error);
    };

    const updateLinkSuccess = (): void => {
      store.emitEmpty('updateLinkSuccess');
    };

    const updateSuccess = (project: Partial<DetailedProject>): void => {
      store.emit('updateProjectSuccess', project);
      patchState(store, {
        loading: false,
        projectIncludeInactive: { ...store.projectIncludeInactive(), ...project as DetailedProject },
        updating: false,
      });
    };

    const updateProject = rxMethod<{ project: Partial<DetailedProject>; undo?: boolean; }>(
      pipe(
        tap(() => patchState(store, { updating: true })),
        switchMap(({ project: updatedProject, undo }) => projectsService.updateProject({ ...store.project()!, ...updatedProject }).pipe(
          tapResponse(
            () => {
              const project = store.project()!;

              if (undo) {
                toastService.success(translateService.instant('common.Toaster.Action_undone') as string);
              } else {
                toastService.success(translateService.instant('common.Toaster.Settings_updated') as string, {
                  buttons: [{
                    command: () => updateProject({ project, undo: true }),
                    shortcut: 'ctrl+z',
                    label: translateService.instant('common.BTN.undo') as string,
                  }],
                });
              }

              navigateToUpdatedProject({ ...project, ...updatedProject });
              updateSuccess(updatedProject);
              updateLinkSuccess();
              setUpdatedProjectToProjects(project.name, updatedProject);
            },
            (error: RequestError) => updateFailure(error),
          ),
        )),
      ),
    );

    const updateLink = rxMethod<{ name: string; undo?: boolean; }>(
      pipe(
        switchMap(({ name, undo }) => projectsService.updateProject({ ...store.project()!, name }).pipe(
          tapResponse(
            (updatedProject) => {
              const project = store.project()!;

              if (undo) {
                toastService.success(translateService.instant('common.Toaster.Action_undone') as string);
              } else {
                toastService.success(translateService.instant('common.Toaster.Project_link_updated') as string, {
                  buttons: [{
                    command: () => updateLink({ name: project.name, undo: true }),
                    shortcut: 'ctrl+z',
                    label: translateService.instant('common.BTN.undo') as string,
                  }],
                });
              }

              navigateToUpdatedProject(updatedProject);
              updateSuccess({ name: updatedProject.name });
              updateLinkSuccess();
              setUpdatedProjectToProjects(project.name, updatedProject);
            },
            (error: RequestError) => {
              updateFailure(error);
              store.emitEmpty('updateLinkFailure');
            },
          ),
        )),
      ),
    );

    const updateName = rxMethod<{ name: string; title: string; undo?: boolean; }>(
      pipe(
        switchMap(({ name, title, undo }) => projectsService.updateProject({ ...store.project()!, name, title }).pipe(
          tapResponse(
            (updatedProject) => {
              const project = store.project()!;

              if (undo) {
                toastService.success(translateService.instant('common.Toaster.Action_undone') as string);
              } else {
                toastService.success(translateService.instant('common.Toaster.Project_renamed') as string, {
                  buttons: [{
                    command: () => updateName({ name: project.name, title: project.title, undo: true }),
                    shortcut: 'ctrl+z',
                    label: translateService.instant('common.BTN.undo') as string,
                  }],
                });
              }

              navigateToUpdatedProject(updatedProject);
              updateSuccess({ name: updatedProject.name, title: updatedProject.title });
              updateLinkSuccess();
              setUpdatedProjectToProjects(project.name, updatedProject);
            },
            (error: RequestError) => updateFailure(error),
          ),
        )),
      ),
    );

    const updateSupportContacts = rxMethod<{ supportContacts: string; undo?: boolean; }>(
      pipe(
        switchMap(({ supportContacts, undo }) => projectsService.updateProject({ ...store.project()!, supportContacts }).pipe(
          tapResponse(
            () => {
              const project = store.project()!;

              if (undo) {
                toastService.success(translateService.instant('common.Toaster.Action_undone') as string);
              } else {
                toastService.success(translateService.instant('common.Toaster.Administrator\'s_contact_updated') as string, {
                  buttons: [{
                    command: () => updateSupportContacts({ supportContacts: project.supportContacts, undo: true }),
                    shortcut: 'ctrl+z',
                    label: translateService.instant('common.BTN.undo') as string,
                  }],
                });
              }

              updateSuccess({ supportContacts });
            },
            (error: RequestError) => updateFailure(error),
          ),
        )),
      ),
    );

    return {
      clear: () => patchState(store, initialState),

      deleteLoginPageBackground: rxMethod<void>(
        pipe(
          switchMap(() => projectsService.deleteProjectLoginPageBackground(store.project()!).pipe(
            tapResponse(
              () => {
                toastService.success(translateService.instant('settings.Branding.Toaster.Background_deleted') as string);
                updateSuccess({ loginBackgroundUrl: '' });
              },
              (error: HttpError) => errorsStore.requestError(error),
            ),
          )),
        ),
      ),

      deleteLogo: rxMethod<void>(
        pipe(
          switchMap(() => projectsService.deleteProjectLogo(store.project()!).pipe(
            tapResponse(
              () => {
                toastService.success(translateService.instant('settings.Branding.Toaster.Logo_deleted') as string);
                updateSuccess({ logoUrl: undefined });
                setUpdatedProjectToProjects(store.project()!.name, { logoUrl: undefined });
              },
              (error: HttpError) => errorsStore.requestError(error),
            ),
          )),
        ),
      ),

      loadProject,

      setViewAsGroup: (viewAsGroup?: ProjectGroup) => {
        if (!viewAsGroup) {
          toastService.info(translateService.instant('common.TOASTER.View_as_closed') as string);
        }

        patchState(store, { viewAsGroup });
      },

      requestSubscriptionPlanUpgrade: rxMethod<PremierPlanFeatures>(
        pipe(
          switchMap((feature) => projectsService.requestSubscriptionPlanUpgrade(store.project()!, feature).pipe(
            tapResponse(
              () => store.emitEmpty('sendUpgradeSubscriptionPlanRequestSuccess'),
              (error: HttpError) => {
                store.emitEmpty('sendUpgradeSubscriptionPlanRequestFailure');
                errorsStore.requestError(error);
              },
            ),
          )),
        ),
      ),

      updateAutoIndex: rxMethod<boolean>(
        pipe(
          switchMap((autoIndexEnabled) => projectsService.updateProject({ ...store.project()!, autoIndexEnabled }).pipe(
            tapResponse(
              () => {
                const message = autoIndexEnabled
                  ? 'common.Toaster.Automatic_indexing_enabled'
                  : 'common.Toaster.Automatic_indexing_disabled';

                toastService.success(translateService.instant(message) as string);
                updateSuccess({ autoIndexEnabled });
              },
              (error: RequestError) => updateFailure(error),
            ),
          )),
        ),
      ),

      updateDocumentsPublishing: rxMethod<boolean>(
        pipe(
          switchMap((documentsPublishingEnabled) => projectsService.updateProject({ ...store.project()!, documentsPublishingEnabled }).pipe(
            tapResponse(
              () => {
                const message = documentsPublishingEnabled
                  ? 'settings.Documents.Toaster.Publishing_enabled'
                  : 'settings.Documents.Toaster.Publishing_disabled';

                toastService.success(translateService.instant(message) as string);
                updateSuccess({ documentsPublishingEnabled });
              },
              (error: RequestError) => updateFailure(error),
            ),
          )),
        ),
      ),

      updateDocumentsVersioning: rxMethod<boolean>(
        pipe(
          switchMap((documentsVersioningEnabled) => projectsService.updateProject({ ...store.project()!, documentsVersioningEnabled }).pipe(
            tapResponse(
              () => {
                const message = documentsVersioningEnabled
                  ? 'settings.Documents.Toaster.Versioning_enabled'
                  : 'settings.Documents.Toaster.Versioning_disabled';

                toastService.success(translateService.instant(message) as string);
                updateSuccess({ documentsVersioningEnabled });
              },
              (error: RequestError) => {
                if (error.errors?.['DocumentsVersioningEnabled']) {
                  toastService.error(translateService.instant('common.Error.Something_went_wrong') as string);
                }

                updateFailure(error);
              },
            ),
          )),
        ),
      ),

      updateLink: (name: string) => updateLink({ name }),

      updateName: (value: { name: string; title: string; }) => updateName(value),

      updateProject: (project: Partial<DetailedProject>) => updateProject({ project }),

      updateProjectUser: (currentUser: ProjectUser) => patchState(store, { currentUser }),

      updateSuccess,

      updateSupportContacts: (supportContacts: string) => updateSupportContacts({ supportContacts }),

      updateThemeColor: rxMethod<{ hoverColor: string; mainColor: string; }>(
        pipe(
          switchMap(({ hoverColor, mainColor }) => projectsService.updateColorTheme(store.project()!, hoverColor, mainColor).pipe(
            tapResponse(
              () => {
                toastService.success(translateService.instant('settings.Branding.Toaster.Theme_color_updated') as string);
                updateSuccess({ mainColor });
                setUpdatedProjectToProjects(store.project()!.name, { mainColor });
              },
              (error: RequestError) => updateFailure(error),
            ),
          )),
        ),
      ),

      uploadLoginPageBackground: rxMethod<File>(
        pipe(
          switchMap((file) => {
            const cancel$ = new Subject<void>();
            const progress$ = new Subject<number>();

            const toastRef = toastService.progress(
              translateService.instant('settings.Branding.Toaster.Background_uploading') as string,
              {
                buttons: [{
                  command: () => cancel$.next(),
                  label: translateService.instant('common.BTN.cancel') as string,
                }],
                progress$,
                sticky: true,
              },
            );

            return projectsService.uploadProjectLoginPageBackground(store.project()!, file).pipe(
              tap((uploadEvent) => {
                progress$.next(uploadEvent.progress);
              }),
              skipWhile((uploadEvent) => !uploadEvent.response),
              tapResponse(
                () => {
                  toastService.success(translateService.instant('settings.Branding.Toaster.Background_changed') as string);
                  loadProject({ hostId: store.project()!.hostId, name: store.project()!.name });
                },
                (error: RequestError) => errorsStore.requestError(error),
                () => {
                  toastRef.close();
                  cancel$.complete();
                  progress$.complete();
                }
              ),
              takeUntil(cancel$),
            );
          }),
        ),
      ),

      uploadLogo: rxMethod<File>(
        pipe(
          switchMap((file) => {
            const cancel$ = new Subject<void>();
            const progress$ = new Subject<number>();

            const toastRef = toastService.progress(
              translateService.instant('settings.Branding.Toaster.Logo_uploading') as string,
              {
                buttons: [{
                  command: () => cancel$.next(),
                  label: translateService.instant('common.BTN.cancel') as string,
                }],
                progress$,
                sticky: true,
              },
            );

            return projectsService.uploadProjectLogo(store.project()!, file).pipe(
              tap((uploadEvent) => {
                progress$.next(uploadEvent.progress);
              }),
              skipWhile((uploadEvent) => !uploadEvent.response),
              tapResponse(
                () => {
                  toastService.success(translateService.instant('settings.Branding.Toaster.Logo_updated') as string);
                  loadProject({ hostId: store.project()!.hostId, name: store.project()!.name });
                },
                (error: RequestError) => errorsStore.requestError(error),
                () => {
                  toastRef.close();
                  cancel$.complete();
                  progress$.complete();
                }
              ),
              takeUntil(cancel$),
            );
          }),
        ),
      ),
    };
  }),
);
