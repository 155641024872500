import { inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from '../../services/auth';

export function authGuard(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
  const activatedRoute = inject(ActivatedRoute);
  const authService = inject(AuthService);

  if (activatedRoute.snapshot.queryParamMap.has('email')) {
    const targetUrl = new URL(location.href);
    const email = activatedRoute.snapshot.queryParamMap.get('email');

    targetUrl.searchParams.delete('email');

    if (authService.isLoggedIn) {
      location.replace(targetUrl.href);
    } else {
      authService.initLogin(targetUrl.href, { email });
    }

    return authService.isLoggedIn;
  }

  if (!authService.isLoggedIn) {
    authService.initLogin();
  }

  if (authService.hasRole('guest')) {
    let route = activatedRouteSnapshot;
    let guestAccess = route.data['guestAccess'] as boolean;

    while (route.firstChild && !guestAccess) {
      route = route.firstChild;
      guestAccess = route.data['guestAccess'] as boolean;
    }

    if (!guestAccess) {
      authService.logout(authService.state || undefined);

      throw new Error('User has a guest role');
    }

    return guestAccess;
  }

  return authService.isLoggedIn;
}
