import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';

import { AuthUserStore } from './auth-user';
import { ErrorsEffects, ErrorsStore } from './errors';
import { provideProjectStore } from './project';
import { provideProjectsStore } from './projects';

export function provideVdrStore(): (Provider | EnvironmentProviders)[] {
  return [
    AuthUserStore,
    ErrorsStore,
    provideEffects([
      ErrorsEffects,
    ]),
    // TODO alina.kuchai@idealscorp.com: remove provideProjectStore, provideProjectsStore after migrate all stores to signal-based
    provideProjectStore(),
    provideProjectsStore(),
  ];
}
