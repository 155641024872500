import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, retry, switchMap, tap, throwError, timer } from 'rxjs';

import { AuthService } from '@ideals/core/auth';
import { FeatureFlagsService } from '@ideals/core/feature-flags';
import { Culture } from '@ideals/core/localization';
import { UploadEvent, UploadService } from '@ideals/services/upload';
import { createHttpParams } from '@ideals/utils/create-http-params';
import { sortItems } from '@ideals/utils/sort-items';
import { uniqueId } from '@ideals/utils/unique-id';

import { environment } from '../../../environments/environment';
import { useViewProjectAction } from '../../business-actions/view-project';
import {
  CreateProject,
  CreateStagingProject,
  DetailedProject,
  DocumentPermission,
  HttpError,
  HttpErrorForbidden,
  ImmediateProjectClosure,
  ListProject,
  ProjectBase,
  ProjectBrandingSettings,
  ProjectClosingReason,
  ProjectRedactionState,
  ProjectRestrictionType,
  ProjectServer,
  ProjectServers,
  ProjectStatus,
  ProjectType,
  ProjectUseCase,
  RequestError,
  ScheduledProjectClosure,
  SubscriptionPlan,
} from '../../models';
import { PremierPlanFeatures } from '../../models';
import { getProjectsUrl } from '../../utils/get-projects-url';

interface ProjectUpdatesDto {
  readonly autoIndexEnabled: boolean;
  readonly projectLinkWithoutEndingCode: string;
  readonly projectName: string;
  readonly supportContacts: string;
}

// eslint-disable-next-line
interface CreateProjectDto {
  readonly accountId?: string;
  readonly accountName?: string;
  readonly administrators: ({
    readonly email: string;
  } | string)[];
  readonly hostId?: number;
  readonly roomName: string;
  readonly useCase: number;
}

const SECOND = 1000;
const ACCESS_CHECK_RETRY_COUNT = 10;

class NeedRetryError extends Error {}

@Injectable({ providedIn: 'root' })
export class ProjectsService {
  readonly #authService = inject(AuthService);
  readonly #featureFlagsService = inject(FeatureFlagsService);
  readonly #httpClient = inject(HttpClient);
  readonly #uploadService = inject(UploadService);

  cancelScheduledProjectClosure(project: DetailedProject): Observable<void> {
    return this.#httpClient.delete<void>(getProjectsUrl(project.hostId, `/${project.id}/closure/schedule`));
  }

  closeProject(project: DetailedProject, closure?: ImmediateProjectClosure): Observable<void> {
    const body = closure
      ? {
        clientSatisfactionComment: closure.satisfactionSurveyResult?.feedback,
        clientSatisfactionRate: closure.satisfactionSurveyResult?.rate,
        closingReason: closure.reason,
        closingReasonComment: closure.comment,
        shouldOrderFlashDrives: false,
      }
      : {};

    return this.#httpClient.post<void>(getProjectsUrl(project.hostId!, `/${project.id}/closure`), body);
  }

  createProject(project: CreateProject): Observable<{
    hostId: string;
    name: string;
    newIdealsSwitchEnabled: boolean;
    type: ProjectType;
  }> {
    if (this.#featureFlagsService.isEnabled('ca-new-room-creation-flow-enabled')) {
      return this.#createProjectFromCA(project);
    }

    const body: CreateProjectDto = {
      administrators: project.administrators.map((email) => ({ email })),
      accountId: project.accountId,
      accountName: project.accountName,
      useCase: project.typeIds[0],
      roomName: project.name,
    };

    return this.#httpClient.post<{
      hostId: number;
      name: string;
      newIdealsSwitchEnabled?: boolean;
      type: ProjectType;
    }>(`${project.serverUrl}/v3/api/v4/rooms${this.#authService.user.isInternalLogin ? '/internal' : ''}`, body)
      .pipe(
        map(({ hostId, name, newIdealsSwitchEnabled, type }) => {
          return {
            hostId: String(hostId),
            name,
            newIdealsSwitchEnabled: newIdealsSwitchEnabled ?? false,
            type,
          };
        }),
      );
  }

  createStagingProject(stagingProject: CreateStagingProject): Observable<ProjectBase> {
    if (this.#featureFlagsService.isEnabled('ca-new-room-creation-flow-enabled')) {
      return this.#createStagingProjectFromCA(stagingProject);
    }

    const { name, hostUrl, ...rest } = stagingProject;

    const body = {
      ...rest,
      roomName: name,
    };

    return this.#httpClient.post<{
      readonly accountId: string;
      readonly createdOn: string;
      readonly displayProjectName: boolean;
      readonly hostId: number;
      readonly id: string;
      readonly mainColor: string;
      readonly name: string;
      readonly title: string;
      readonly type: ProjectType;
    }>(`${hostUrl}/v3/api/v4/rooms`, body).pipe(
      map((dto): ProjectBase => ({
        accountId: dto.accountId,
        canView: true,
        createdOn: new Date(dto.createdOn),
        displayName: dto.displayProjectName,
        hostId: `${dto.hostId}`,
        id: dto.id,
        mainColor: dto.mainColor,
        name: dto.name,
        status: 'Active',
        title: dto.title,
        type: dto.type,
      })),
    );
  }

  createTrialProject(
    sourceProject: ProjectBase,
    name: string
  ): Observable<{ detailedProject: DetailedProject; listProject: ListProject; }> {
    if (this.#featureFlagsService.isEnabled('ca-new-room-creation-flow-enabled')) {
      return this.#createTrialProjectFromCA(sourceProject, name);
    }

    // consider change the route on the BE side
    const url = getProjectsUrl(sourceProject.hostId, `/${sourceProject.id}/preparation`);

    return this.#httpClient.post<{
      readonly accountId: string;
      readonly createdOn: string;
      readonly displayProjectName: boolean;
      readonly id: string;
      readonly mainColor: string;
      readonly name: string;
      readonly title: string;
      readonly type: ProjectType;
    }>(url, { projectName: name }).pipe(
      map((dto): ListProject => ({
        accountId: dto.accountId,
        canView: true,
        createdOn: new Date(dto.createdOn),
        displayName: dto.displayProjectName,
        hostId: sourceProject.hostId,
        id: dto.id,
        isFavorite: false,
        mainColor: dto.mainColor,
        name: dto.name,
        status: 'Active',
        title: dto.title,
        type: dto.type,
        usedStorage: 0,
        usersCount: 1,
      })),
      switchMap((listProject) => {
        return this.loadProject(listProject.hostId, listProject.id).pipe(
          map((detailedProject) => ({ detailedProject, listProject })),
        );
      }),
    );
  }

  deleteProjectLoginPageBackground(project: DetailedProject): Observable<void> {
    return this.#httpClient.delete<void>(getProjectsUrl(project.hostId!, `/${project.id}/general-settings/login-background`));
  }

  deleteProjectLogo(project: DetailedProject): Observable<void> {
    return this.#httpClient.delete<void>(getProjectsUrl(project.hostId!, `/${project.id}/general-settings/logo`));
  }

  loadProject(hostId: string, nameOrId: string): Observable<DetailedProject> {
    return this.#loadProjectDto(hostId, nameOrId).pipe(
      switchMap((projectDto) => this.#loadProjectSpecialFoldersDto(hostId, projectDto.id).pipe(
        map((specialFoldersDto): DetailedProject => ({
          accountId: projectDto.accountId,
          allowedEmailDomains: projectDto.allowedEmailDomains?.split(','),
          autoIndexEnabled: projectDto.autoIndexEnabled,
          brandingEnabled: projectDto.brandingEnabled,
          canView: true,
          closingDate: projectDto.closingDate ? new Date(projectDto.closingDate) : null,
          createdOn: new Date(projectDto.createdOn),
          displayName: projectDto.displayProjectName,
          documentsPublishingEnabled: projectDto.documentsPublishingEnabled,
          documentsVersioningEnabled: projectDto.documentsVersioningEnabled,
          hostId,
          id: projectDto.id,
          isTwoFactorAuthMandatory: projectDto.isTwoFactorAuthMandatory,
          labelsEnabled: projectDto.labelsEnabled,
          linkEndingCode: projectDto.endingCode ? `_${projectDto.endingCode}` : '',
          loginBackgroundEnabled: projectDto.loginBackgroundEnabled,
          loginBackgroundUrl: projectDto.loginBackgroundUrl || undefined,
          logoUrl: projectDto.logoUrl ?? undefined,
          mainColor: projectDto.mainColor,
          name: projectDto.name,
          newIdealsSwitchEnabled: projectDto.newIdealsSwitchEnabled ?? false,
          newRolesActivated: projectDto.newRolesActivated,
          oldQnAEnabled: projectDto.oldQnAEnabled,
          qnaStatus: projectDto.qnaStatus,
          redactionEnabled: projectDto.redactionEnabled,
          serverName: projectDto.serverName,
          specialFolders: {
            qnaAttachmentsId: specialFoldersDto.qaFolder.id,
            qnaAttachmentsPermission: specialFoldersDto.qaFolder.permission,
            recycleBinId: specialFoldersDto.recycleBin.id,
            recycleBinPermission: specialFoldersDto.recycleBin.permission,
            rootId: specialFoldersDto.root.id,
          },
          status: projectDto.status,
          subscriptionPlan: projectDto.plan,
          supportContacts: projectDto.supportContacts ?? '',
          termsOfUseEnabled: projectDto.termsOfUseEnabled,
          title: projectDto.title,
          type: projectDto.type,
          uiId: projectDto.uiId,
          useNewFileStorage: projectDto.useNewFileStorage,
          watermarksEnabled: projectDto.watermarksEnabled,
        })),
      )),
      map((project) => ({
        ...project,
        canView: useViewProjectAction(project),
      })),
      catchError((httpError: HttpError) => {
        if (httpError.status !== 'Not Found' && httpError.status !== 'Forbidden') {
          return throwError(() => httpError);
        }

        const error = (httpError as HttpErrorForbidden).error as {
          agreementAcceptanceRequired: boolean;
          emailCodeRequired: boolean;
          secondFactorRequired: boolean;
        } | undefined;
        let restrictionType: ProjectRestrictionType = 'AccessRequired';

        if (error?.secondFactorRequired) {
          restrictionType = 'SecondFactorRequired';
        } else if (error?.emailCodeRequired) {
          restrictionType = 'EmailCodeRequired';
        } else if (error?.agreementAcceptanceRequired) {
          restrictionType = 'AgreementAcceptanceRequired';
        }

        return throwError(() => ({
          ...httpError,
          status: 'Forbidden',
          error: restrictionType,
        }));
      }),
    );
  }

  loadProjectBrandingSettings(hostId: string, name: string): Observable<ProjectBrandingSettings> {
    const url = getProjectsUrl(hostId, `/${name}/branding-settings`);

    return this.#httpClient.get<{
      readonly loginBackgroundUrl: string;
      readonly logoUrl: string;
      readonly mainColor: string;
    }>(url).pipe(
      map((dto) => ({
        loginBackgroundUrl: dto.loginBackgroundUrl || undefined,
        logoUrl: dto.logoUrl || undefined,
        mainColor: dto.mainColor,
      })),
    );
  }

  loadProjectByUiId(hostId: string, uiId: string): Observable<ProjectBase> {
    const params = createHttpParams({ hostIdAndRoomId: `${hostId}_${uiId}` });

    return this.#httpClient.get<{
      displayProjectName: boolean;
      hasSupportAccess: boolean;
      hostNumber: number;
      id: string;
      logoUrlV3: string;
      mainColor: string;
      name: string;
      status: ProjectStatus;
      title: string;
      type: ProjectType;
    }>('/api/ca/rooms', { params }).pipe(
      map((dto): ProjectBase => ({
        accountId: 'Unknown',
        canView: true,
        createdOn: new Date(0),
        displayName: dto.displayProjectName,
        hasSupportAccess: dto.hasSupportAccess,
        hostId: `${dto.hostNumber}`,
        id: dto.id,
        logoUrl: dto.logoUrlV3 || undefined,
        mainColor: dto.mainColor,
        name: dto.name,
        status: dto.status,
        title: dto.title,
        type: dto.type,
      })),
      map((project) => ({
        ...project,
        canView: useViewProjectAction(project),
      })),
    );
  }

  loadProjectRedactionState(project: DetailedProject): Observable<ProjectRedactionState> {
    return this.#httpClient.get<ProjectRedactionState>(getProjectsUrl(project.hostId, `/${project.id}/metadata/redaction`));
  }

  loadProjects(administrated?: true): Observable<ListProject[]> {
    const params = createHttpParams({ administrated: administrated ?? undefined });

    return this.#httpClient.get<ListProject[]>('/api/ca/projects', { params }).pipe(
      map((projects) => projects.map((project): ListProject => ({
        ...project,
        canView: useViewProjectAction(project),
        createdOn: new Date(project.createdOn),
        isFavorite: false,
        lastAccessedOn: project.lastAccessedOn ? new Date(project.lastAccessedOn) : undefined,
      }))),
      map((projects) => this.#sortProjects(projects)),
    );
  }

  loadProjectServers(culture: Culture, accountIds: string[] = []): Observable<ProjectServers> {
    const body = {
      accountIds,
      culture,
      continentName: this.#authService.geolocation().continentName,
      countryName: this.#authService.geolocation().countryName,
    };

    return this.#httpClient.post<{
      readonly accountsToAvailableServersMap: {
        readonly accountId: string;
        readonly servers: ProjectServer[];
      }[];
      readonly allServers: ProjectServer[];
    }>('/api/ca/servers', body).pipe(
      map(({ allServers, accountsToAvailableServersMap }) => {
        const servers: ProjectServers = {
          all: allServers,
          ...accountsToAvailableServersMap.reduce<Record<string, ProjectServer[]>>((acc, value) => {
            acc[value.accountId] = value.servers;

            return acc;
          }, {}),
        };

        return servers;
      }),
    );
  }

  loadSatisfactionSurveyVisibility(project: DetailedProject): Observable<boolean> {
    return this.#httpClient.get<{ display: boolean; }>(getProjectsUrl(project.hostId, `/${project.id}/user/survey`)).pipe(
      map(({ display }) => display)
    );
  }

  loadScheduledProjectClosure(project: DetailedProject): Observable<ScheduledProjectClosure | undefined> {
    return this.#httpClient.get<{
      closingDate: string | null;
      closingReason: ProjectClosingReason | null;
      closingReasonComment: string | null;
    }>(getProjectsUrl(project.hostId, `/${project.id}/closure/schedule`)).pipe(
      map((dto) => {
        return dto.closingDate
          ? {
            comment: dto.closingReasonComment ?? undefined,
            date: new Date(dto.closingDate),
            reason: dto.closingReason!,
            satisfactionSurveyResult: null,
          }
          : undefined;
      })
    );
  }

  loadUseCases(): Observable<ProjectUseCase[]> {
    return this.#httpClient.get<ProjectUseCase[]>(getProjectsUrl(environment.projectProviderUrlDefaultId, '/use-cases')).pipe(
      map((useCases) => this.#sortUseCases(useCases)),
    );
  }

  requestSubscriptionPlanUpgrade(project: DetailedProject, feature: PremierPlanFeatures): Observable<void> {
    return this.#httpClient.post<void>(getProjectsUrl(project.hostId!, `/${project.id}/general-settings/upgrade-request`), {
      upgradeRequestOptions: feature,
    });
  }

  scheduleProjectClosure(project: DetailedProject, closure: ScheduledProjectClosure): Observable<void> {
    const body = {
      closingDate: closure.date,
      clientSatisfactionComment: closure.satisfactionSurveyResult?.feedback,
      clientSatisfactionRate: closure.satisfactionSurveyResult?.rate,
      closingReason: closure.reason,
      closingReasonComment: closure.comment,
      shouldOrderFlashDrives: false,
    };

    return this.#httpClient.put<void>(getProjectsUrl(project.hostId!, `/${project.id}/closure/schedule`), body);
  }

  updateColorTheme(project: DetailedProject, hoverColor: string, mainColor: string): Observable<void> {
    return this.#httpClient.put<void>(getProjectsUrl(project.hostId!, `/${project.id}/general-settings/color-theme`), {
      // hoverColor is needed for vdr3
      hoverColor,
      mainColor,
    });
  }

  updateProject(project: DetailedProject): Observable<DetailedProject> {
    return this.#httpClient.put<ProjectUpdatesDto>(getProjectsUrl(project.hostId!, `/${project.id}/general-settings`), {
      autoIndexEnabled: project.autoIndexEnabled,
      documentsPublishingEnabled: project.documentsPublishingEnabled,
      documentsVersioningEnabled: project.documentsVersioningEnabled,
      projectLinkWithoutEndingCode: project.name.slice(0, project.name.lastIndexOf(project.linkEndingCode)),
      projectName: project.title,
      supportContacts: project.supportContacts,
    }).pipe(
      map(({ autoIndexEnabled, projectName, supportContacts }) => ({
        ...project,
        autoIndexEnabled,
        supportContacts,
        title: projectName,
      }))
    );
  }

  uploadProjectLoginPageBackground(project: DetailedProject, file: File): Observable<UploadEvent> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('id', uniqueId());
    formData.append('contentLength', file.size.toString());

    return this.#uploadService.uploadFile(
      getProjectsUrl(project.hostId!, `/${project.id}/general-settings/login-background`),
      formData,
    );
  }

  uploadProjectLogo(project: DetailedProject, file: File): Observable<UploadEvent> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('id', uniqueId());
    formData.append('contentLength', file.size.toString());

    return this.#uploadService.uploadFile(
      getProjectsUrl(project.hostId!, `/${project.id}/general-settings/logo`),
      formData,
    );
  }

  #checkProjectAccessAfterCaCreation(hostId: string, projectName: string, retries = ACCESS_CHECK_RETRY_COUNT): Observable<unknown> {
    const needRetryError = new NeedRetryError('Creation of project in progress');

    return this.#httpClient.get<'CreationStarted' | 'Created'>(getProjectsUrl(hostId, `/${projectName}/creation-status`))
      .pipe(
        tap({
          next: (response) => {
            if (response === 'CreationStarted') {
              throw needRetryError;
            }
          },
          error: (error: RequestError) => {
            if (error.status === 'Not Found') {
              throw needRetryError;
            }

            throw error as unknown as Error;
          },
        }),
        retry({
          count: retries,
          delay: (error: Error | NeedRetryError, retryCount) => {
            if (error instanceof NeedRetryError) {
              return timer(retryCount * SECOND);
            }

            throw error;
          },
        })
      );
  }

  #createProjectFromCA(project: CreateProject): Observable<{
    hostId: string;
    name: string;
    newIdealsSwitchEnabled: boolean;
    type: ProjectType;
  }> {
    const body: CreateProjectDto = {
      administrators: project.administrators,
      accountId: project.accountId,
      accountName: project.accountName,
      useCase: project.typeIds[0],
      roomName: project.name,
      hostId: project.hostId,
    };

    return this.#httpClient.post<{
      readonly hostId: number;
      readonly id: string;
      readonly newIdealsSwitchEnabled?: boolean;
      readonly roomName: string;
      readonly type: ProjectType;
    }>('/api/ca/rooms', body)
      .pipe(
        map(({ hostId, id, roomName, newIdealsSwitchEnabled, type }) => {
          return {
            id,
            hostId: String(hostId),
            name: roomName,
            newIdealsSwitchEnabled: newIdealsSwitchEnabled ?? false,
            type,
          };
        }),
        switchMap((createdProject) => {
          return this.#checkProjectAccessAfterCaCreation(createdProject.hostId, createdProject.id)
            .pipe(map(() => createdProject));
        }),
      );
  }

  #createStagingProjectFromCA(stagingProject: CreateStagingProject): Observable<ProjectBase> {
    const { name, administrators, ...rest } = stagingProject;

    const body = {
      ...rest,
      administrators: administrators.map(({ email }) => email),
      roomName: name,
    };

    return this.#httpClient.post<{
      readonly accountId: string;
      readonly createdOn: string;
      readonly displayProjectName: boolean;
      readonly hostId: number;
      readonly id: string;
      readonly mainColor: string;
      readonly roomName: string;
      readonly title: string;
      readonly type: ProjectType;
    }>('/api/ca/rooms', body).pipe(
      map((dto): ProjectBase => ({
        accountId: dto.accountId,
        canView: true,
        createdOn: new Date(dto.createdOn),
        displayName: dto.displayProjectName,
        hostId: `${dto.hostId}`,
        id: dto.id,
        mainColor: dto.mainColor,
        name: dto.roomName,
        status: 'Active',
        title: dto.title,
        type: dto.type,
      })),
      switchMap((createdProject) => {
        return this.#checkProjectAccessAfterCaCreation(createdProject.hostId, createdProject.id)
          .pipe(map(() => createdProject));
      }),
    );
  }

  #createTrialProjectFromCA(
    sourceProject: ProjectBase,
    name: string
  ): Observable<{ detailedProject: DetailedProject; listProject: ListProject; }> {
    return this.#httpClient.post<{
      readonly accountId: string;
      readonly createdOn: string;
      readonly displayProjectName: boolean;
      readonly id: string;
      readonly mainColor: string;
      readonly projectType: ProjectType;
      readonly roomName: string;
      readonly title: string;
    }>(`/api/ca/rooms/${sourceProject.id}/preparation`, { roomName: name }).pipe(
      map((dto): ListProject => ({
        accountId: dto.accountId,
        canView: true,
        createdOn: new Date(dto.createdOn),
        displayName: dto.displayProjectName,
        hostId: sourceProject.hostId,
        id: dto.id,
        isFavorite: false,
        mainColor: dto.mainColor,
        name: dto.roomName,
        status: 'Active',
        title: dto.title,
        type: dto.projectType,
        usedStorage: 0,
        usersCount: 1,
      })),
      switchMap((listProject) => {
        return this.#checkProjectAccessAfterCaCreation(listProject.hostId, listProject.id)
          .pipe(map(() => listProject));
      }),
      switchMap((listProject) => {
        return this.loadProject(listProject.hostId, listProject.id).pipe(
          map((detailedProject) => ({ detailedProject, listProject })),
        );
      }),
    );
  }

  #loadProjectDto<DTO = {
    readonly accountId: string;
    readonly allowedEmailDomains: string;
    readonly autoIndexEnabled: boolean;
    readonly brandingEnabled: boolean;
    readonly closingDate: string | null;
    readonly createdOn: string;
    readonly displayProjectName: boolean;
    readonly documentsPublishingEnabled: boolean;
    readonly documentsVersioningEnabled: boolean;
    readonly endingCode: string;
    readonly id: string;
    readonly isTwoFactorAuthMandatory: boolean;
    readonly labelsEnabled: boolean;
    readonly loginBackgroundEnabled: boolean;
    readonly loginBackgroundUrl: string;
    readonly logoUrl?: string;
    readonly mainColor: string;
    readonly name: string;
    readonly newIdealsSwitchEnabled?: boolean;
    readonly newRolesActivated: boolean;
    readonly oldQnAEnabled: boolean;
    readonly plan: SubscriptionPlan;
    readonly qnaStatus: 'Activated' | 'Disabled' | 'Enabled';
    readonly redactionEnabled: boolean;
    readonly serverName: string;
    readonly status: ProjectStatus;
    readonly supportContacts: string | null;
    readonly termsOfUseEnabled: boolean;
    readonly title: string;
    readonly type: ProjectType;
    readonly uiId: string;
    readonly useNewFileStorage: boolean;
    readonly watermarksEnabled: boolean;
  }>(hostId: string, nameOrId: string): Observable<DTO> {
    return this.#httpClient.get<DTO>(getProjectsUrl(hostId, `/${nameOrId}`));
  }

  #loadProjectSpecialFoldersDto<DTO = {
    readonly qaFolder: {
      readonly id: string;
      readonly permission: DocumentPermission;
    };
    readonly recycleBin: {
      readonly id: string;
      readonly permission: DocumentPermission;
    };
    root: {
      readonly id: string;
    };
  }>(hostId: string, projectId: string): Observable<DTO> {
    return this.#httpClient.get<DTO>(getProjectsUrl(hostId!, `/${projectId}/documents/special-folders`));
  }

  #sortProjects<T extends ProjectBase>(projects: T[]): T[] {
    return sortItems(
      projects,
      [
        { field: 'lastAccessedOn', order: -1 },
        { field: 'name', order: 1 },
      ],
      {
        lastAccessedOn: (value) => value ?? new Date(0),
      }
    );
  }

  #sortUseCases(useCases: ProjectUseCase[]): ProjectUseCase[] {
    const sorted = sortItems(
      useCases,
      [
        { field: 'position', order: 1 },
      ],
    );

    return sorted.map((useCase) => {
      return useCase.useCases
        ? { ...useCase, useCases: this.#sortUseCases(useCase.useCases) }
        : useCase;
    });
  }
}
