import { DestroyRef, inject, Injector } from '@angular/core';
import { signalStoreFeature, withMethods } from '@ngrx/signals';
import { filter, finalize, map, Observable, Subject, take, takeUntil } from 'rxjs';

interface ListenerConfig {
  readonly destroyRef?: DestroyRef;
  readonly injector?: Injector;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withStoreEvents<Events extends Record<string, unknown>>() {
  return signalStoreFeature(
    withMethods(() => {
      const events$ = new Subject<{ data: unknown; key: keyof Events; }>();
      const on$ = <Key extends keyof Events>(key: Key, config: ListenerConfig = {}): Observable<Events[Key]> => {
        const destroy$ = new Subject<void>();

        if (config?.injector) {
          config.injector.get(DestroyRef).onDestroy(() => destroy$.next());
        } else if (config?.destroyRef) {
          config.destroyRef.onDestroy(() => destroy$.next());
        } else {
          // automatically complete the events stream when the listener is created in an injection context
          try {
            inject(DestroyRef).onDestroy(() => destroy$.next());
          } catch (error) {}
        }

        return events$.asObservable().pipe(
          filter((event) => event.key === key),
          map((event) => event.data as Events[Key]),
          takeUntil(destroy$),
          finalize(() => setTimeout(() => destroy$.complete()))
        );
      };

      return {
        emit: <Key extends keyof Events>(key: Key, data: Events[Key]) => events$.next({ key, data }),
        emitEmpty: <Key extends keyof Events>(key: Key) => events$.next({ key, data: undefined }),
        on$: <Key extends keyof Events>(key: Key, config: ListenerConfig = {}): Observable<Events[Key]> => {
          return on$(key, config);
        },
        once$: <Key extends keyof Events>(key: Key, config: ListenerConfig = {}): Observable<Events[Key]> => {
          return on$(key, config).pipe(take(1));
        },
        events$: () => events$.asObservable(),
      };
    })
  );
}
