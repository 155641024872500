import { isArray } from 'chart.js/helpers';

import { ProjectStatus } from '../../models';

export function checkProjectStatus(project: { status: ProjectStatus; }, status: ProjectStatus | ProjectStatus[]): boolean {
  if (isArray(status)) {
    return status.includes(project.status);
  }

  return project.status === status;
}
